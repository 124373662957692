.MuiStepper-horizontal {
    justify-content: center;
}
.arrow-connector {
    padding: 0 4.5vw;
}

.back {
    position: absolute;
    top: 23px;
    left: 20px;
}

.next {
    position: absolute;
    top: 23px;
    right: 20px;
}