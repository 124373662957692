.carousel-references {
    display: flex;
    flex-direction: row;
    width: 85%;
    margin: 0 auto;
    flex-wrap: nowrap;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
}
.referencesBloc {
    /* height: 700px; */
    background: white;
    align-items: center;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    padding: 100px 0 100px 0;
}
.subtitle {
    color: #102D47;
    font-size: 18px;
    font-weight: 200;
    line-height: 22px;
}
.owl-item.active {
    width: auto !important;
}
.owl-stage {
    justify-content: center;
    display: flex;
    align-items: center;
}
.nav-icon {
    width: 48px;
    height: 48px;
    border-radius: 25px;
    background: #F2F6FA;
    flex: none;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0 20px;
    cursor: pointer;
    z-index: 3;
}
/* .owl-carousel.owl-loaded {
    display: block !important;
} */
.swiper-wrapper {
    align-items: center;
}