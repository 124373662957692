
.filterBloc{
    /* background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)), url('../../assets/ribbon1.svg'), url('../../assets/background1.jpg'); */
    z-index: 1;
    position: relative;
    padding-bottom: 130px;
}

.filterBlocOverlay {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)), url('../../assets/ribbon1.svg');
  background-repeat: no-repeat;
  background-size: cover, auto, cover;
  background-position: center center, 400px -230px, bottom center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.underline {
    background-image: url("../../assets/ribbon2.svg");
    background-position: 7px 52px;
    background-repeat: no-repeat;
    background-size: auto;
    padding: 0 20px;
    margin-left: -20px;
    animation: animatedBackground 0.5s linear;
    transition: 0.3s;
}

@keyframes animatedBackground {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 7px 52px;
    }
  }