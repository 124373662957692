.filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    background: white;
    border-radius: 15px;
    /* max-width: 1164px; */
    min-height: 88px;
    margin: 0 auto;
    /* padding: 10px; */
    position: relative;
    z-index: 1;
    /* overflow: hidden */
}
.inpt>div{
    padding: 0 15px
}
.filters>div {
    height: 70px;
}
.DayPicker {
    position: absolute;
    background: white;
    border-radius: 15px;
    box-shadow: 0 10px 20px #0000001f;
    top: 60px;
    left: 0;
    z-index: 3;
    width: 300px
}
.DayPicker-Day.DayPicker-Day--today {
    color: #00b4ff;
}
.DayPicker-Day.DayPicker-Day--selected {
    background: #00b4ff !important;
}
.error-msg {
    color: #ff4e2e;
    background: #ffffff1c;
    width: fit-content;
    padding: 0 10px;
    border-radius: 30px;
    margin-top: 10px;
    font-size: 13px;
    position: absolute;
    transition: ease 0.2s;
}
