.booking-form {
    background: white;
    border-radius: 15px;
    margin-bottom: 20px;
}
.form-block {
    margin-top: 15px;
    margin-bottom: 30px;
}

.label {
    font-size: 16px;
    color: #102D47;
    padding: 0 0.5rem;
}
.Mui-checked + .MuiFormControlLabel-label {
    opacity: 1;
}
.checklist-column {
    display: flex;
    flex-direction: column;
}
.MuiCheckbox-root span.MuiIconButton-label {
    margin-right: 15px;
}
.MuiCheckbox-root + span {
    opacity: 0.4;
}
.MuiCheckbox-colorSecondary.Mui-checked + span {
    opacity: 1;
}
.big-input {
    max-width: 350px;
    margin-right: 15px;
}
.arrow-down {
    padding: 0 15px;
    margin-top: 5px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: url("../../assets/chevrons-down-small.svg");
    background-repeat: no-repeat;
    margin-top: 5px;
}

input[name="date"] {
    background: url("../../assets/calendar-small.svg");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

button.minus, p.minus {
    border: none;
    background: transparent;
    position: absolute;
    top: 12px;
    left: 15px;
    cursor: pointer;
}

button.plus, p.plus {
    border: none;
    background: transparent;
    position: absolute;
    top: 12px;
    right: 30px;
    cursor: pointer;
}

p.error {
    color: tomato;
    font-size: 11px;
    position: absolute;
}
.DayPicker {
    position: absolute;
    background: white;
    border-radius: 15px;
    box-shadow: 0 10px 20px #0000001f;
    top: 70px;
    left: 0;
    z-index: 3;
}