.done {
    background: white;
    padding: 30px 60px;
    border-radius: 15px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-image: url('../../assets/ribbon9.svg');
    background-repeat: no-repeat;
    background-size: cover;
}