.contactBloc {
    /* height: 700px; */
    background: url('../../assets/gradientbg2.jpg');
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: cover;
    display: flex;
    /* overflow: hidden; */
    flex-direction: row;
    padding: 100px 0 50px 0;
    position: relative;
    flex-wrap: wrap;
}
.titleBox {
    width: 460px;
    margin: 0 50px 0 100px;
}
.subtitle {
    color: #102D47;
    font-size: 18px;
    font-weight: 200;
    line-height: 22px;
}
.contact-infos {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
img.ribbon7 {
    position: absolute;
    top: -200px;
    left: -300px;
    z-index: 1;
}