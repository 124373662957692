@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: Poppins, sans-serif;
  background: #f5f6f7;
}

main {
    max-width: 80rem;
    margin: auto;
    padding: 0 50px 0 50px;
}
  
  main > h1 {
    text-align: center;
  }
  
  .Add-article {
    margin: 2rem auto;
    padding: 1rem;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
  
  .Add-article input {
    display: block;
    margin: 1rem auto;
    width: 20rem;
    padding: 0.5rem;
    border: 1px solid #00b8e6;
    border-radius: 10px;
    outline: none;
  }
  
  .Add-article button {
    width: 21rem;
    padding: 0.7rem;
    border: 1px solid #00b8e6;
    background: #00b8e6;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    outline: none;
  }
  
  .Add-article button:hover {
    background: #0093b8;
  }
  
  .Add-article button:disabled {
    background: #d8d8d8;
    border: 1px solid #d8d8d8;
    cursor: not-allowed;
  }
  
  .Article {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: #fff;
    border-radius: 10px;
  }
  
  .Article:not(:last-child) {
    border-bottom: 2px solid #f5f6f7;
  }
  
  .Article h1 {
    color: #222;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 1.7rem;
  }
  
  .Article p {
    padding-right: 1rem;
    color: #444;
  }
  .Article button {
    padding: 0.5rem 0.7rem;
    border: 1px solid #d34b4b;
    background: #d34b4b;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    outline: none;
  }
  
  .Article button:hover {
    background: #e06a6a;
  }