.list-howitworks {
    display: flex;
    justify-content: space-evenly;
    width: 85%;
    margin: 0 auto;
    flex-wrap: wrap;
}
.howItWorksBloc {
    /* height: 700px; */
    background: url('../../assets/gradientbg3.jpg');
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: cover;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    padding: 100px 0 100px 0;
    position: relative;
}
.howItWorks {
    /* height: 700px; */
    align-items: center;
    display: flex;
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
}
img.ribbon6 {
    position: absolute;
    top: 80px;
    left: -5px;
}
.titleBox {
    width: 360px;
    margin: 0 100px;
}
.subtitle {
    color: #102D47;
    font-size: 18px;
    font-weight: 200;
    line-height: 22px;
}
p.tip-text {
    color: #102D47;
    font-size: 14px;
    font-weight: 200;
    margin-left: 15px;
}