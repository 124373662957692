.badge-number {
    color: white;
}
.badge {
    display: flex;
    z-index: 2;
}
img.arrow {
    position: absolute;
    left: -85px;
    top: 80px;
}