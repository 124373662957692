.list-salles {
    display: flex;
    justify-content: space-evenly;
    width: 85%;
    margin: 0 auto;
    flex-wrap: wrap;
}
.sallesBloc {
    /* height: 700px; */
    background-image: url('../../assets/gradientbg2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    padding: 100px 0 100px 0;
}
img.ribbon3 {
    position: absolute;
    top: -40px;
    left: 250px;
}
img.ribbon4 {
    position: absolute;
    top: 830px;
    left: -1700px;
}