.footer {
    max-height: 450px;
    display: inline-block;
    width: 100%;
    position: relative;
    z-index: 1;
    background: #102D47;
}
.logo {
    width: 197px;
    height: 46.38px;
}
.footer-box {
    padding: 50px 100px
}
img.ribbon8 {
    margin-left: -150px;
    margin-top: -110px;
}
.footer-copyright {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 20px 100px;
    background: #102d47;
}
.footer-copyright p {
    color: white;
    font-size: 16px;
    font-weight: 300;
}