.salles-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.overlay {
    width: 270px;
    height: 180px;
    background: linear-gradient(0deg, rgba(16, 45, 71, 0.6), transparent);
    position: absolute;
    top: 0;
    left: 0;
}
.placeholder {
    width: 270px;
    height: 190px;
    background: url('../../assets//placeholder.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}