
.text-box {
    bottom: 20px;
    position: absolute;
    left: 25px;
}
.text-box h4 {
    font-weight: 400;
    color: white;
    font-size: 25px;
}
.text-box h6 {
    font-weight: 400;
    color: white;
    font-size: 20px;
}