.header {
    padding: 20px 0 20px 0;
    display: inline-block;
    width: 100%;
    position: relative;
    z-index: 1;
}
.logo {
    width: 197px;
    height: auto;
}
nav {
    float: right;
}
ul {
    list-style-type: none;
    text-align: center;
    margin: 0;
    padding: 0;
    position: relative;
}
li {
    display: inline-block;
    font-size: 18px;
    padding: 15px;
}
li a {
    line-height: 22px;
    color: white;
    text-decoration: none;
    /* transition: .2s; */
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgb(0 0 0 / 0%);
    position: relative;
    overflow: hidden;
}
li a:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: #00B4FF;
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
li a:hover:before, li a:focus:before, li a:active:before {
    left: 0;
    right: 0;
}
.booking {
    border: 1px solid white;
    border-radius: 15px;
    transition: 0.3s;
}
.booking:hover {
    background-color: #00B4FF;
    color: white;
}
a.booking-button {
    margin-left: 50px;
    color: white;
}
.booking-header {
    background: #00B4FF;
    padding: 15px 0; 
    display: flex;
    align-items: center;
    justify-content: center;
}
a.Mui-selected {
    color: #0080FF;
    font-size: 20px;
    font-weight: 500;
}