.recap-form {
    background: white;
    border-radius: 15px;
    margin-bottom: 20px;
}
.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}
.row > div {
    width: 500px;
}
.row-small {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}