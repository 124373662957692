.list-whyizivent {
    display: flex;
    justify-content: space-evenly;
    width: 85%;
    margin: 0 auto;
    flex-wrap: wrap;
}
.whyIziventBloc {
    /* height: 700px; */
    background: white;
    align-items: center;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    padding: 100px 0 100px 0;
    position: relative;
}
img.ribbon5 {
    position: absolute;
    top: -650px;
    left: 270px;
}